import { createSlice } from '@reduxjs/toolkit';

interface State {
  isOpen: boolean;
}

export const FavoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    isOpen: false
  } as State,
  reducers: {
    changeIsOpen(state, action) {
      state.isOpen = action.payload;
    }
  }
});

export const { changeIsOpen } = FavoritesSlice.actions;
