import { createSlice } from '@reduxjs/toolkit';

interface State {
  darkMode: 'dark' | 'light' | '';
  currency: string;
  user: null | Record<string, any>;
  favorites: Record<string, any>;
}

export const GlobalSlice = createSlice({
  name: 'global',
  initialState: {
    darkMode: '',
    currency: 'USD',
    favorites: [],
    user: null
  } as State,
  reducers: {
    changeDarkMode(state, action) {
      state.darkMode = action.payload;
    },
    changeCurrency(state, action) {
      state.currency = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setFavorites(state, action) {
      state.favorites = action.payload;
    }
  }
});

export const { changeDarkMode, changeCurrency, setUser, setFavorites } = GlobalSlice.actions;
