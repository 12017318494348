import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AgentModalSlice } from './slices/AgentModal.slice';
import { FavoritesSlice } from './slices/favorites.slice';
import { GlobalSlice } from './slices/global.slice';
import { LoginModalSlice } from './slices/loginModal.slice';

export const store = configureStore({
  reducer: {
    loginModal: LoginModalSlice.reducer,
    agentModal: AgentModalSlice.reducer,
    favorites: FavoritesSlice.reducer,
    global: GlobalSlice.reducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootStateOrAny = ReturnType<typeof store.getState>;
export const useTDispatch: () => AppDispatch = useDispatch;
export const useTSelector: TypedUseSelectorHook<RootStateOrAny> = useSelector;
