import { createSlice } from '@reduxjs/toolkit';

interface State {
  isOpen: boolean;
  data: {
    name: string;
    phone: string;
    email: string;
    message: string;
  };
}

export const AgentModalSlice = createSlice({
  name: 'AgentModal',
  initialState: {
    isOpen: false,
    data: {
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  } as State,
  reducers: {
    changeIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    changeMessageData(state, action) {
      state.data = { ...state.data, [action.payload.name]: action.payload.value };
    }
  }
});

export const { changeIsOpen, changeMessageData } = AgentModalSlice.actions;
