import { createSlice } from '@reduxjs/toolkit';

interface State {
  isOpen: boolean;
  isLoginMode: boolean;
  loginData: {
    username: string;
    password: string;
  };
  registerData: {
    username: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

export const LoginModalSlice = createSlice({
  name: 'LoginModal',
  initialState: {
    isOpen: false,
    isLoginMode: true,
    loginData: {
      username: '',
      password: ''
    },
    registerData: {
      username: '',
      lastName: '',
      email: '',
      phone: ''
    }
  } as State,
  reducers: {
    changeIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    changeLoginData(state, action) {
      state.loginData = { ...state.loginData, [action.payload.name]: action.payload.value };
    },
    changeRegisterData(state, action) {
      state.registerData = { ...state.registerData, [action.payload.name]: action.payload.value };
    },
    setIsLoginMode(state, action) {
      state.isLoginMode = action.payload;
    }
  }
});

export const { changeIsOpen, changeLoginData, changeRegisterData, setIsLoginMode } =
  LoginModalSlice.actions;
